import { FAQPageJsonLd } from "next-seo";
import React from "react";

const FAQJson = ({ data }) => {
  return (
    data &&
    data?.length > 0 && (
      <FAQPageJsonLd
        mainEntity={data?.map((faq) => {
          return {
            questionName: faq?.question || "",
            acceptedAnswerText: faq?.answer || "",
          };
        })}
      />
    )
  );
};

export default FAQJson;
