import React from "react";
import dynamic from "next/dynamic";
import WebPageJson from "../Commons/WebPageJson";
import { NextSeo } from "next-seo";
const OrganisationJson = dynamic(() => import("../Commons/OrganisationJson"));
const Meta = dynamic(() => import("../Commons/Meta"));

const CommonSeoSchema = ({
  title = "",
  description = "",
  keywords = "",
  url = "",
  imageUrl = "",
  imageType = "",
  imageAlt = "",
  languageAlternates = {},
  videoUrl = "",
  showOrganisation = true,
  showWebPageJson = true,
  noIndex = false,
  noFollow = false,
}) => {
  return (
    <>
      <Meta
        title={title}
        description={description}
        keywords={keywords}
        imageUrl={imageUrl}
        imageType={imageType}
        imageAlt={imageAlt}
        languageAlternates={languageAlternates}
        url={url}
        videoUrl={videoUrl}
      />
      <NextSeo noindex={noIndex} nofollow={noFollow} />
      {showOrganisation && <OrganisationJson />}
      {showWebPageJson && <WebPageJson data={{ url, description }} />}
    </>
  );
};

export default CommonSeoSchema;
