import React from "react";
import SectionsMap from "./PageBuilder.model";
import dynamic from "next/dynamic";
const Footer = dynamic(() => import("../Footer/Footer.controller"));

const PageBuilder = ({ sections, showFooter = true }) => {
  if (sections?.length < 1) {
    return <>{showFooter !== false ? <Footer /> : null}</>;
  }
  return (
    <>
      {sections?.map((section) => {
        const { id, __component, ...props } = section;
        const sectionName = __component?.split("section.")[1] || "";
        const Component = SectionsMap[`${sectionName}`];
        if (Component) {
          return (
            <Component
              key={section?.section_id}
              id={section?.section_id}
              {...props}
            />
          );
        }
        return null;
      })}
      {showFooter !== false ? <Footer /> : null}
    </>
  );
};

export default PageBuilder;
