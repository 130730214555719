import { WebPageJsonLd } from "next-seo";
import React from "react";

const WebPageJson = ({ data = {} }) => {
  const currentDate = new Date().toISOString();
  return (
    <WebPageJsonLd
      description={data.description}
      id={data.url}
      lastReviewed={currentDate}
      reviewedBy={{
        type: "Person",
        name: "Mayank Grover",
      }}
    />
  );
};

export default WebPageJson;
