import React from "react";

const PageRenderer = ({ components }) => {
  return (
    <>
      {components.map((item, index) => {
        const {
          id,
          hashURL,
          comp: Component,
          props,
          showCondition = true,
        } = item;
        if (showCondition) {
          return <Component id={hashURL} key={id} {...props} />;
        }
        return null;
      })}
    </>
  );
};

export default PageRenderer;
