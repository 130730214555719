import dynamic from "next/dynamic";

const CoursesListing = dynamic(() =>
  import("./CoursesListing/CoursesListing.controller")
);
const ImgsGridController = dynamic(() =>
  import("./ImgsGrid/Imgs_Grid.controller")
);
const TextCardBGrid = dynamic(() =>
  import(
    "@/mvcComponents/CommonUI/Sections/TextCardBGrid/TextCardBGrid.controller"
  )
);
const CardPopupA = dynamic(() => import("./CardPopupA/CardPopupA.controller"));
const CardPopupB = dynamic(() => import("./CardPopupB/CardPopupB.controller"));
const TextCardA_Grid = dynamic(() =>
  import("./TextCardA_Grid/TextCardA_Grid.controller")
);
const ScholarshipBannerSection = dynamic(() =>
  import(
    "@/mvcComponents/PageComponents/ScholarshipPage/ScholarshipBannerSection/ScholarshipBannerSection.controller"
  )
);
const HaloTxtImgB = dynamic(() =>
  import("./HaloTxtImgB/HaloTxtImgB.controller")
);
const LatestJobs = dynamic(() => import("./LatestJobs/LatestJobs.controller"));
const IconTxtListB_Grid = dynamic(() =>
  import("./IconTxtListB_Grid/IconTxtListB_Grid.controller")
);
const CurriculumSection = dynamic(() =>
  import("./CurriculumSection/CurriculumSection.controller")
);
const JobsSectionController = dynamic(() =>
  import("./JobsSection/JobsSection.controller")
);
const InlineContentScrollableSection = dynamic(() =>
  import(
    "./InlineContentScrollableSection/InlineContentScrollableSection.controller"
  )
);
const FeaturesList = dynamic(() =>
  import("./FeaturesList/FeaturesList.controller")
);
const FaqsList = dynamic(() => import("./FaqsList/FaqsList.controller"));
const RichText = dynamic(() => import("./RichText/RichText.controller"));
const PillAList = dynamic(() => import("./PillAList/PillAList.controller"));
const TextLinks = dynamic(() => import("./TextLinks/TextLinks.controller"));
const DropGridA = dynamic(() => import("./DropGridA/DropGridA.controller"));
const DropList_A = dynamic(() => import("./DropList_A/DropList_A.controller"));
const HaloTxtImgA = dynamic(() =>
  import("./HaloTxtImgA/HaloTxtImgA.controller")
);
const ImgTxtC_Grid = dynamic(() =>
  import("./ImgTxtC_Grid/ImgTxtC_Grid.controller")
);
const GamesByAlumni = dynamic(() =>
  import("./GamesByAlumni/GamesByAlumni.controller")
);
const CourseHaloController = dynamic(() =>
  import("./CourseHalo/CourseHalo.controller")
);
const ImgCardC_Grid = dynamic(() =>
  import("./ImgCardC_Grid/ImgCardC_Grid.controller")
);
const ProcessA_Grid = dynamic(() =>
  import("./ProcessA_Grid/ProcessA_Grid.controller")
);
const ImageAutoScroll = dynamic(() =>
  import("./ImageAutoScroll/ImageAutoScroll.controller")
);
const ImgCardA_Scroll = dynamic(() =>
  import("./ImgCardA_Scroll/ImgCardA_Scroll.controller")
);
const IconTextCardGrid = dynamic(() =>
  import("./IconTextCardGrid/IconTextCardGrid.controller")
);
const CourseLeads_Halo = dynamic(() =>
  import("./CourseLeads_Halo/CourseLeads_Halo.controller")
);
const ImageTextA_Scroll = dynamic(() =>
  import("./ImageTextA_Scroll/ImageTextA_Scroll.controller")
);
const StatisticsSection = dynamic(() =>
  import("./StatisticsSection/StatisticsSection.controller")
);
const PaidCouresListController = dynamic(() =>
  import("./PaidCoursesList/PaidCouresList.controller")
);
const CourseFeeStructure = dynamic(() =>
  import("./CourseFeeStructure/CourseFeeStructure.controller")
);
const PersonACard_Scrollable = dynamic(() =>
  import("./PersonACard_Scrollable/PersonACard_Scrollable.controller")
);
const PersonBCard_Scrollable = dynamic(() =>
  import("./PersonBCard_Scrollable/PersonBCard_Scrollable.controller")
);
const PersonCCard_AutoScroll = dynamic(() =>
  import("./PersonCCard_AutoScroll/PersonCCard_AutoScroll.controller")
);
const AssessmentsListController = dynamic(() =>
  import(
    "@/mvcComponents/CommonUI/Sections/AssessmentsList/AssessmentsList.controller"
  )
);
const OutlinedIconTextCardGrid_Image = dynamic(() =>
  import(
    "./OutlinedIconTextCardGrid_Image/OutlinedIconTextCardGrid_Image.controller"
  )
);
const ScholarshipsList = dynamic(() =>
  import("./ScholarshipsList/ScholarshipsList.controller")
);
const TxtLstController = dynamic(() => import("./TxtLst/TxtLst.controller"));
const CourseHaloV2Controller = dynamic(() =>
  import("./CourseHaloV2/CourseHaloV2.controller")
);
const ImgCardA_GridController = dynamic(() =>
  import("./ImgCardA_Grid/ImgCardA_Grid.controller")
);

const Compiler = dynamic(() => import("./Compiler/compiler.controller"));

const TabLinks = dynamic(() => import("./TabLinks/TabLinks.controller"));

const SectionsMap = {
  "grid-a": IconTextCardGrid,
  "lead-form": CourseLeads_Halo,
  "course-a": PaidCouresListController,
  "txt-links": TextLinks,
  "pill-a-list": PillAList,
  faq: FaqsList,
  "ita-scroll": ImageTextA_Scroll,
  "dropper-list-a": DropList_A,
  "drop-grid-a": DropGridA,
  assessment: AssessmentsListController,
  "txt-content": RichText,
  "pa-scroll": PersonACard_Scrollable,
  "pb-scroll": PersonBCard_Scrollable,
  "pc-auto": PersonCCard_AutoScroll,
  "course-halo": CourseHaloController,
  "img-auto": ImageAutoScroll,
  "course-fee": CourseFeeStructure,
  "game-desc": GamesByAlumni,
  "list-image-a": OutlinedIconTextCardGrid_Image,
  statistics: StatisticsSection,
  "img-card-c": ImgCardC_Grid,
  "img-card-a": ImgCardA_GridController,
  "img-text-c": ImgTxtC_Grid,
  "halo-txt-img-a": HaloTxtImgA,
  "halo-txt-img-b": HaloTxtImgB,
  "process-a": ProcessA_Grid,
  "txt-card-a": TextCardA_Grid,
  "txt-card-b": TextCardBGrid,
  "latest-jobs": LatestJobs,
  "icon-txt-list-b": IconTxtListB_Grid,
  syllabus: CurriculumSection,
  jobs: JobsSectionController,
  "inline-a": InlineContentScrollableSection,
  "ftr-list": FeaturesList,
  "card-popup-a": CardPopupA,
  "card-popup-b": CardPopupB,
  "ctr-grid-a": ScholarshipsList,
  "schlp-dts": ScholarshipBannerSection,
  txtlst: TxtLstController,
  crshalov2: CourseHaloV2Controller,
  compiler: Compiler,
  "tab-links": TabLinks,
  "img-grid": ImgsGridController,
  "courses-listing": CoursesListing,
};
export default SectionsMap;
